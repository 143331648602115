import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component
export default class DropdownMenu extends Vue {
  get brand(): string {
    return require('../../../../../whitelabel.config').name;
  }

  /* get myBrand(): string {
    return `${capitalize(this.$t('common.my') as string)} ${capitalize(this.brand)}`;
  } */

  @Action logOut!: Function

  onLogOutClick(): void {
    this.logOut({ redirect: '/' });
  }
}
