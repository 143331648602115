import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LanguagesAccount extends Vue {
  get currentLang(): string {
    return this.$route.params.lang || 'nl';
  }

  get fullPath(): string {
    const { lang: paramsLang } = this.$route.params;
    const { fullPath } = this.$route;
    // Return if theres no language in the url (edge case)
    if (!paramsLang) {
      return fullPath;
    }
    // Removing the current language from the fullPath
    const extractedUrlLang = fullPath.split('/')[1];
    const startSubstring = `/${extractedUrlLang}`.length;
    return fullPath.substring(startSubstring, fullPath.length);
  }
}
