import { Vue, Component } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({
  components: {
  },
})
export default class ResponsiveLinks extends Vue {
  @Action logOut!: Function
}
